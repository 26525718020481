<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    width="1000"
  >
    <v-card>
      <v-card-text>
        <v-row class="pa-3">
          <v-col cols="12" sm="12" lg="6">
            <v-carousel
              class="carousel-img-prods"
              delimiter-icon="mdi-minus"
            >
              <v-carousel-item
                v-for="(img, i) in inventory.producto.imagenes"
                :key="i"
                :src="defaultImage(img)"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" sm="12" lg="6">
            <p class="display-2 text--primary">
              {{ inventory.producto.nombre_producto }}
            </p>
            <v-rating
              :value="inventory.producto.calificacion"
              color="primary"
              background-color="secondary"
              dense
              half-increments
              readonly
              size="24"
            >
            </v-rating>
            <div class="text--primary mt-8">
              {{ inventory.producto.descripcion_corta }} <br>
              {{ inventory.producto.descripcion_larga }} <br>
              {{ inventory.producto.sku }} <br>
              {{ inventory.producto.marca.dato }} <br>
              Se vende por: {{ inventory.producto.unidad_medida.dato}}
            </div>
            <div class="display-1 text--primary mt-6">
              Precio: ${{ inventory.producto.precio }}
            </div>
            <div>
              <small
                v-if="inventory.producto.backorder && inventory.producto.backorder.backorder"
                class="text-caption"
              >
                (Backorder con tiempo de entrega de {{ inventory.producto.backorder.tiempo_entrega_dias }} días)
              </small>
              <small
                v-else
                class="text-caption"
              >
                ({{ inventory.cantidad_disponible }} disponibles)
              </small>
            </div>
            <div class="text-primary my-2">
              <small
                v-for="offer in inventory.descuento"
                :key="offer.id"
              >
                Obten el {{offer.porcentaje_descuento*100}}% de descuento en compra mínima de {{offer.cantidad_compra_minima}} ó más productos<br>
              </small>
            </div>
            <div
              class="text-h6 text--primary mt-6"
            >
              Presentación:
              <div
                v-for="(opcion, i) in inventory.producto.opcion_producto"
                :key="i"
                class="text-h6 text--primary d-inline-block mx-6">
                {{ opcion.nombre_opcion }}
              </div>
            </div>
            <v-divider class="mt-4"></v-divider>
            <span>Archivos:</span>
            <v-btn
              v-for="doc in inventory.producto.documentos"
              :key="doc.id"
              class="ma-2"
              text
              icon
              color="primary"
              :href="doc.documento"
              target="_blank"
            >
              <v-icon>mdi-file-download-outline</v-icon>
            </v-btn>
            <v-divider></v-divider>
            <v-card-actions v-if="showingCart">
              <v-btn
                color="primary"
                class="mt-6"
                block
                @click="addProductoToCart(prod)"
              >
                Añadir al carrito
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: {
    inventory: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('usuario', ['showingCart'])
  },
  methods: {
    ...mapMutations('cart', ['addProduct']),

    addProductoToCart(product) {
      this.addProduct(product)
    },
    defaultImage(image) {
      return image !== undefined ? image.imagen : require('@/assets/image-not-found.png')
    },
  }
}
</script>

<style>

</style>