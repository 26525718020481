<template>
  <v-row
    v-if="productLoading"
    class="my-6 px-6"
  >
    <v-col
      v-for="i in 12"
      :key="i"
      cols="3"
      sm="12"
      md="3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="card"
        elevation="2"
        max-width="250"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row
    v-else-if="products.length"
    class="my-6 px-6"
  >
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="2"
    >
      <filter-products
        :showSearchChip="showSearchChip"
        :searchText="lastSearch"
        :categoryOfSearch="categoryToSearch"
        @filterUpdated="filterProducts"
        @cleanFilters="cleanFilters"
        @closeSearchChip="cleanFilters"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="10"
    >
      <div v-if="productosFiltrados.length">
        <paginate
          name="products"
          :list="productosFiltrados"
          :per="perPage"
          class="pl-0"
        >
          <v-row>
            <product-item
              v-for="product in paginated('products')"
              :product="product"
              :key="product.id"
              :id="`product${product.id}`"
              :ref="`product${product.id}`"
              @addToCart="addProductToCart"
              @addToWishlist="addToWishlist(product)"
              @removeOfWishlist="removeOfWishlist(product)"
            >
            </product-item>
          </v-row>
        </paginate>
        <paginate-links
          for="products"
          :async="true"
          :limit="5"
          :show-step-links="true"
          :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
          @change="onPageChange"
        >
        </paginate-links>
      </div>
      <v-row v-else>
        <h1>No hay productos con las características buscadas.</h1>
      </v-row>
    </v-col>
    <product-detail-modal
      v-if="openDialog"
      v-model="openDialog"
      :inventory=inventorySelected
    />
  </v-row>
  
  <v-alert
    v-else-if="!products.length"
    class="my-6 px-6"
    border="right"
    colored-border type="error"
    elevation="2"
  >
    No hay productos que mostrar.
  </v-alert>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import ProductItem from './ProductItem'
  import { inventoryMixin } from '@/mixins/inventory.js'
  import ProductDetailModal from '../../components/utils/ProductDetailModal.vue'

  export default {
    metaInfo: {
      title: 'Productos'
    },
    components: {
      ProductItem,
      ProductDetailModal,
      FilterProducts: () => import('./Filter.vue')
    },
    mixins: [inventoryMixin],
    data () {
      return {
        paginate: ['products'],
        perPage: 12,
        productsFiltered: [],
        categoriaFiltered: null,
        marcaFiltered: null,
        ratingFiltered: null,
        noFiltered: null,
        subCategoriaFiltered: null,
        openDialog: false,
        inventorySelected: {},
        inventoryId: null,
        showSearchChip: false,
        categoryToSearch: null
      }
    },
    computed: {
      ...mapState('products', [
          'products',
          'totalItems',
          'lastPageInventory',
          'distributorId',
          'sucursalId',
          'productSearch',
          'isSearch',
          'productLoading',
          'lastSearch'
          ]),

      productosFiltrados() {
        let productos = this.products
        // let productos = this.isSearch ? this.productSearch : this.products
        if(this.noFiltered) {
          return productos
        }
        return productos
          .filter(product => this.categoriaFiltered ?
            product.producto.categoria_producto.dato === this.categoriaFiltered.val : true)
          .filter(product => this.ratingFiltered ?
            this.ratingFiltered.val === 1 ?
            product.producto.calificacion >= 0 :
            product.producto.calificacion >= this.ratingFiltered.val : true) 
          .filter(product => this.subCategoriaFiltered ?
            product.producto.subcategoria_producto.dato === this.subCategoriaFiltered.val : true)
          .filter( product => this.marcaFiltered ?
            product.producto.marca.dato === this.marcaFiltered.val : true)
      }
    },
    watch: {
      inventoryId(val) {
        // console.log('Valor de inventory cambió?', val);
        this.openProductModal()
      }
    },
    created() {
      if (this.lastPageInventory === 0) {
        const lastPage = this.calculatePaginateLimit(this.totalItems, this.perPage)
        // console.log('Last page: ', lastPage);
        this.setLastPageInventory(lastPage)
      }
    },
    mounted() {
      // console.log('Productos onMounted');
      if (this.isSearch) {
        this.inventoryId = this.$route.query.id
        this.openProductModal()
      }
    },
    beforeRouteUpdate(to, from) {
      // console.log('beforeRouteUpdate');
      // console.log('to', to);
      // console.log('from', from);
      if (this.isSearch) {
        // console.log('isSearch', this.isSearch);
        // console.log('to.query.id', to.query.id);
        this.inventoryId = to.query.id
        const filterObj = { filterName: 'Categoria', val: to.query.filtro }
        this.filterProducts(filterObj)
        this.categoryToSearch = to.query.filtro
      }
    },
    beforeRouteLeave(from, to, next) {
      // console.log('beforeRouteLeave');
      if(this.isSearch) {
        this.setIsSearch(false)
      }
      next()
    },
    methods: {
      ...mapMutations('cart', ['addProduct']),
      ...mapMutations('products', ['setLastPageInventory', 'setLastSearch']),
      ...mapActions('products', [
        'fetchPaginatedProducts',
        'setIsSearch',
        'getPaginatedProductSearch'
      ]),
      ...mapActions('usuario', ['addProductToWishlist', 'removeProductOfWishlist']),
      addProductToCart(product) {
        this.addProduct(product)
      },
      addToWishlist(product) {
        //console.log('El producto que se va a agregar:', product);
        this.addProductToWishlist(product.producto.id)
        this.updateInWishlistOfProduct(true, product)
      },
      removeOfWishlist(product) {
        this.removeProductOfWishlist(product.producto.id)
        this.updateInWishlistOfProduct(false, product)
      },
      updateInWishlistOfProduct(newValue, product) {
        //console.log('dentro de updateInWishlistOfProduct', newValue, product);
        const index = this.productosFiltrados.findIndex(p => p === product)
        // console.log('index', index);
        this.productosFiltrados[index].inWishlist = newValue
      },
      filterProducts(filter) {
        // console.log('filterProducts filter:', filter);
        if(filter.filterName === 'TodosC') {
          this.categoriaFiltered = null
        }
        if(filter.filterName === 'Categoria') {
          this.subCategoriaFiltered = null
          this.categoriaFiltered = filter
          this.noFiltered = null
        }
        if(filter.filterName === 'subCategoria') {
          this.subCategoriaFiltered = filter
        }
        if(filter.filterName === 'TodosSub') {
          this.subCategoriaFiltered = null
        }
        if(filter.filterName === 'Marca') {
          this.marcaFiltered = filter
          this.noFiltered = null
        }
        if(filter.filterName === 'TodosM') {
          this.marcaFiltered = null
        }
        if(filter.filterName === 'Rating') {
          this.ratingFiltered = filter
          this.noFiltered = null
        }
        if(filter.filterName === 'TodosR') {
          this.ratingFiltered = null
        }
      },
      onPageChange(toPage, fromPage) {
        const size = this.isSearch ? this.productSearch.length : this.products.length
        if (toPage === this.calculatePaginateLimit(size, this.perPage) 
          && toPage < this.lastPageInventory) {
            this.responsePage++
            if (this.isSearch) {
              this.getPaginatedProductSearch(this.responsePage)
            } else {
              const paginatedData = {
                distributorId: this.distributorId,
                sucursalId: this.sucursalId,
                page: this.responsePage
              }
              this.fetchPaginatedProducts(paginatedData)
            }
        }
      },
      setInventoryId() {
        if (this.isSearch) {
          // console.log('id? ', this.$route.query.id);
          this.inventoryId = this.$route.query.id
        }
      },
      openProductModal() {
        if (this.inventoryId) {
          // console.log('productosFiltrados: ', this.productosFiltrados);
          const inventory = this.productosFiltrados.find(p => p.id == this.inventoryId)
          // console.log('Inventory', inventory);
          this.inventorySelected = inventory
          this.showSearchChip = true
          this.openDialog = true
        }
      },
      cleanFilters() {
        this.showSearchChip = false
        this.setIsSearch(false)
        this.setLastSearch('')
        this.noFiltered = true
        this.categoriaFiltered = null
        this.categoryToSearch = 'Todos'
      }
    },
  }
</script>

<style>
.pagination {
  display: flex;
  margin-top: 16px;
  text-align: center;
  justify-content: center;
  list-style-type: none;
}
.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.page-link {
  font-weight: bolder;
}
.active {
  background: #2D4761;
}
</style>